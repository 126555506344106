<template>
  <div>
    <v-container>
      <div class="text-center">
        <p class="font-weight-black ma-0 p-0">Дипломы и сертификаты</p>
      </div>
      <v-row
      >
        <v-col
          v-for="project in projects"
          :key="project.id"
          cols="12"
          sm="6"
          md="3"
          style="display: flex; justify-content: center;"
        >
          <v-card max-width="240" @click="handleClick(project)">
            <v-img
              :src="project.img"
              height="200px"
              class="border-none"
            ></v-img>
            <v-card-title>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on" class="text-truncate">
                    {{ project.title }}
                  </span>
                </template>
                <span>{{ project.title }}</span>
              </v-tooltip>
            </v-card-title>
            <v-card-subtitle>{{ project.category }}</v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    projects: [
      {
        id: 1,
        title: "Маматова Таманно",
        category: "Студент",
        img: require("@/assets/pdfs/mamatovatamanno.jpg"),
        pdfname: "diplom2",
      },
      {
        id: 2,
        title: "Марупова Севара",
        category: "Студент",
        img: require("@/assets/pdfs/marupovasevara.jpg"),
        pdfname: "diplom1",
      },
      {
        id: 3,
        title: "Шеркулова Ирода",
        category: "Студент",
        img: require("@/assets/pdfs/sherkulovairoda.jpg"),
        pdfname: "diplomElegant401326",
      },
      {
        id: 4,
        title: "Денисенко Марина",
        category: "Студент",
        img: require("@/assets/pdfs/denisenkomarina.jpg"),
        pdfname: "diplomElegant401331",
      },
      {
        id: 5,
        title: "Обидова Парвина",
        category: "Студент",
        img: require("@/assets/pdfs/obidovaparvina.jpg"),
        pdfname: "diplomElegant401322",
      },
      {
        id: 6,
        title: "Джумаева Нилуфар",
        category: "Студент",
        img: require("@/assets/pdfs/djumaevanilufar.jpg"),
        pdfname: "diplomElegant401318",
      },
    ],
  }),
  methods: {
    handleClick(project) {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (isMobile) {
        const link = document.createElement("a");
        link.href = `/pdfs/${project.pdfname}.pdf`;
        link.download = `${project.pdfname}.pdf`;
        link.click();
      } else {
        window.open(`/pdfs/${project.pdfname}.pdf`, "_blank");
      }
    },
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
